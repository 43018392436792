import React from 'react'
import './experience.css'

const Experience = () => {

    return (
        <section id='experience'>experience</section>
    )
}

export default Experience